import React from 'react';

// Styles
import './index.css';

const Loading: React.FC = () => (
  <div className="loader">
    <i className="spinner fa-3x fas fa-circle-notch fa-spin" />
  </div>
);

export default Loading;
