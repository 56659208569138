import React from 'react';
import { Helmet } from 'react-helmet';

// ReactRouter
import { Route, Switch } from 'react-router-dom';

// Notifications
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Public Pages
import LoginPage from 'containers/LoginPage';
import ForgotPassword from 'containers/ForgotPassword';
import ResetPassword from 'containers/ResetPassword';
import ResetPasswordMobile from 'containers/ResetPasswordMobile';

// Private Pages
import AppLogged from 'containers/AppLogged';

// Styles
import './App.css';

// Configuration
import { URLS } from 'config';
import { QueryParamProvider } from 'use-query-params';

export class App extends React.PureComponent {
  public render() {
    return (
      <div className="RareguruAPP">
        <Helmet
          titleTemplate="%s - Rareguru Dashboard"
          defaultTitle="Rareguru Dashboard"
        >
          <meta name="description" content="Rareguru Dashboard" />
        </Helmet>
        <ToastContainer />
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route exact path={URLS.LOGIN} component={LoginPage} />
            <Route exact path={URLS.FORGOT_PASSWORD} component={ForgotPassword} />
            <Route exact path={URLS.RESET_PASSWORD} component={ResetPassword} />
            <Route exact path={URLS.RESET_PASSWORD_MOBILE} component={ResetPasswordMobile} />
            <Route component={AppLogged} />
          </Switch>
        </QueryParamProvider>

      </div>
    );
  }
}
