/* eslint-disable no-console */
import { ApolloLink, InMemoryCache, ApolloClient } from 'apollo-boost';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';

const superLink = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }),
  new ApolloLink((operation, forward) => {
    if (forward) {
      return forward(operation).map(response => {
        try {
          if (response.data) {
            const keys = Object.keys(response.data);
            keys.forEach(k => {
              const result = response.data![k];
              if (typeof result === 'object' && result.token) {
                localStorage.setItem('token', result.token);
              }
            });
          }
        } catch (e) {
          console.log(e.message);
        }
        return response;
      });
    }
    return null;
  }),
  setContext((_, { headers }) => {
    const token = localStorage.getItem('token') || '';
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }),
  createUploadLink({
    credentials: 'same-origin',
    uri: `${process.env.REACT_APP_API || ''}/`,
  }),
]);

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: superLink,
});
