export const URLS = {
  HOME: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token?',
  RESET_PASSWORD_MOBILE: '/reset_password/:token',
  USERS: '/users',
  USER_DETAIL: '/users/:id',
  USER_DETAIL_DEACTIVATE: '/users/:id/deactivate',
  USER_DETAIL_ACTIVATE: '/users/:id/activate',
  USER_DETAIL_FLAG: '/users/:id/flag',
  USER_DETAIL_UNFLAG: '/users/:id/unflag',
  USER_DETAIL_SEND_EMAIL: '/users/:id/send-email',
  USER_SEARCH: '/users/search',
  USER_REPORT_HISTORY: '/users/:id/report-history',
  ADMIN: '/admin',
  ADMIN_INVITE: '/admin/invite',
  DIAGNOSES_LIST: '/diagnoses',
  DIAGNOSIS: '/diagnosis/:id',
  CHARITIES: '/charities',
  NOTIFICATIONS: '/notifications',
  ERROR_500: '/500',
};


export const USER_STATUS = {
  DISABLED: 'DISABLED',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const USER_STATUS_ACTIONS = {
  DEACTIVATE: 'DEACTIVATE',
  REACTIVATE: 'REACTIVATE',
};

export const ERROR_MSGS: {[key: string]: string} = {
  LOGIN_GENERIC: 'There was an error trying to login, please try again later.',
  AUTH_TOKEN_INVALID: 'It looks like your reset password link got expired, try to reset your password again.',
  FORGOT_PASSWORD_GENERIC: 'There was an error trying to reset your password, please try again later.',
  INVALID_CREDENTIALS: 'Incorrect username or password',
  ADMIN_NOT_FOUND: 'The selected admin doesnt exists.',
  ADMIN_ALREADY_EXIST: 'The admin already exists.',
  ADMIN_INVITE_SEND_ERROR: 'There was an error trying to invite an administrator.',
  ADMIN_INVITE_DEFAULT: 'There was an error trying to invite an administrator.',
  USER_DEACTIVATE_DEFAULT: 'There was an error trying to deactivate the user',
  USER_ACTIVATE_DEFAULT: 'There was an error trying to activate the user',
  USER_FLAG_DEFAULT: 'There was an error trying to flag the user',
  USER_UNFLAG_DEFAULT: 'There was an error trying to unflag the user',
  USER_SEND_MAIL_DEFAULT: 'There was an error trying to send an email to the user.',
};

export const SUCCESS_MSGS = {
  USER_ACTIVATE: 'User activated successfully',
  USER_DEACTIVATE: 'User deactivated successfully',
  USER_FLAG: 'User flagged successfully',
  USER_UNFLAG: 'User unflagged successfully',
};

export const USERS_SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const USERS_LIST_PAGE_SIZE = 10;
